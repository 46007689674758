import { Button, Input, Layout, Popconfirm, Table, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/services/Utils';
import LayoutHeader from '../../../components/LayoutHeader';
import { TestProjectsVisualStore, TestProjectWizardStore } from '../stores';
import { TestProject, TestProjectListModel } from '../types';
import { TestProjectCreationWizard } from '.';
import { ColumnProps } from 'antd/lib/table';
import { CopyOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
const { Content } = Layout;

type Props = {
    store: TestProjectsVisualStore;
    wizardStore: TestProjectWizardStore
};

const TestProjectsList: React.FC<Props> = ({store, wizardStore}) => {
    React.useEffect(() => {
        if (store.currentProject) {
            store.setSearchValue('');
            store.loadTestProjects();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    const getRecordError = (record: TestProjectListModel) => {
        const isInvalid = record.hasValidPackages === false || record.hasValidTopics === false;
        const hasErrorMessage = record.errors && record.errors.length > 0;
        if (isInvalid && hasErrorMessage) {
            return <ExclamationCircleOutlined style={{color: '#B80C02', fontSize: '18px'}}/>;
        }

        return null;
    };

    const goToTestProjectLastRun = (testProjectId: string, runId: string) => {
        store.goToTestResults(testProjectId, runId);
    };

    const getLastRunContent = (record: TestProjectListModel) => {
        if (!record.lastRunId || record.isRunning === true || record.lastRunTime == null || record.lastRunFuzzy == null) {
            return null;
        }
   
        return (
            <Button    
                className="link-button"
                type="link" 
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    goToTestProjectLastRun(record.id, record.lastRunId!);
                }}
                style={{marginLeft: -16}}
            >
                <span data-id-cells="lastRunTime">
                    {Utils.formatDateStringShort(record.lastRunTime)}
                </span>
            </Button>
        );
    };

    const getLastRunFuzzyPercent = (record: TestProjectListModel) => {
        if (!record.lastRunId || record.isRunning === true || record.lastRunTime == null || record.lastRunFuzzy == null) {
            return null;
        }
   
        return Math.round((record.lastRunFuzzy + Number.EPSILON));
    };

    const handleRunTestClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, testProjectId: string) => {
        e.stopPropagation();
        store.runTestProject(testProjectId);
    };

    const columns: ColumnProps<TestProjectListModel>[] = [{
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeLocaleCompare(a.name, b.name),
        render: (text: string, record: TestProjectListModel) => (
            <span data-id-cells="name">{record.name}</span>
        )
    }, {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: 'Created time',
        defaultSortOrder: 'descend',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeDateStringCompare(a.createdTime, b.createdTime),
        render: (date: string) => (
            <span data-id-cells="createdTime">{Utils.formatDateStringShort(date)}</span>
        )
    }, {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Created by',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeLocaleCompare(store.getUserNameById(a.createdBy), store.getUserNameById(b.createdBy)),
        render: (createdBy: string) => (
            <span data-id-cells="createdBy">{store.getUserNameById(createdBy)}</span>
        )
    }, {
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        title: 'Updated by',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeLocaleCompare(store.getUserNameById(a.updatedBy), store.getUserNameById(b.updatedBy)),
        render: (updatedBy: string) => (
            <span data-id-cells="updatedBy">{store.getUserNameById(updatedBy)}</span>
        )
    }, {
        key: 'lastRunId',
        dataIndex: 'lastRunId',
        title: 'Last run',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeDateStringCompare(a.lastRunTime, b.lastRunTime),
        render: (_: string, record: TestProjectListModel) => getLastRunContent(record)
    }, {
        key: 'lastRunFuzzy',
        dataIndex: 'lastRunFuzzy',
        title: 'Result vs Baseline',
        sorter: (a: TestProjectListModel, b: TestProjectListModel) => Utils.safeDateStringCompare(a.lastRunTime, b.lastRunTime),
        render: (_: string, record: TestProjectListModel) => {
            if (record.lastRunId && record.lastRunError) {
                return (
                    <Tooltip title={record.lastRunError}><WarningOutlined style={{color: 'red'}} /></Tooltip>
                );
            }
            const lastRunFuzzy = getLastRunFuzzyPercent(record);
            if (lastRunFuzzy == null) {
                return null;
            }

            return (
                <span data-id-cells="lastRunFuzzy">{lastRunFuzzy} %</span>
            );
        }
    }, {
        key: 'warning',
        dataIndex: 'warning',
        title: '',
        width: 40,
        render: (_: unknown, record: TestProjectListModel) => (
            getRecordError(record)
        )
    }, {
        key: 'isRunning',
        dataIndex: 'isRunning',
        title: '',
        render: (_: unknown, record: TestProjectListModel) => (
            <>{record.isRunning === true 
                ? <span style={{color: '#B80C02'}}>Running...</span> 
                : record.hasValidBaselines && <Tooltip title="Run project">
                    <Button 
                        data-id={`test-projects-run-${record.id}`}
                        type="link"
                        size="small"
                        onClick={(e) => handleRunTestClick(e, record.id)} 
                        icon={ <i style={{verticalAlign: 'bottom'}} className="alpha-icon md play-icon green" /> }
                    />
                </Tooltip> }
            </>
        )
    }, {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        width: 120,
        render: (_: unknown, record: TestProjectListModel) => (
            <div 
                data-id-cells="actions"
                className="row-actions-wrapper"
                style={{ minWidth: 100}}
                onClick={e => e.stopPropagation()}
            >
                <Tooltip title="Create a copy of the project">
                    <Observer>
                        {() => (
                            <Button 
                                type="link" 
                                size="small" 
                                data-id="button-copy-test-project" 
                                icon={<CopyOutlined style={{color: '#9ba0ab'}}/>} 
                                style={{marginRight: 8, verticalAlign: 'text-bottom'}}
                                disabled={store.isCopyingTestProject}
                                loading={store.isCopyingTestProject}
                                onClick={() => store.createCopyOfTestProject(record.id)}
                            />
                        )}
                    </Observer>
                </Tooltip>
                <Tooltip title="Edit project">
                    <Button 
                        type="link" 
                        size="small" 
                        data-id="button-edit-test-project" 
                        icon={<i className="alpha-icon lg edit-icon" />} 
                        style={{marginRight: 8}}
                        onClick={() => editTestProject(record.id)}
                    />
                </Tooltip>
                <Popconfirm
                    title="Are you sure you want to delete this test project?"
                    onConfirm={() => store.deleteTestProject(record.id)}
                >
                    <Tooltip title="Delete project">
                        <Button type="link" size="small" data-id="button-edit-test-project" icon={<i className="alpha-icon lg delete-icon" />} />
                    </Tooltip>
                </Popconfirm>
            </div>
        )
    }];

    const editTestProject = (testProjectId: string) => {
        wizardStore.setUpTestProject(testProjectId);
        wizardStore.setIsCreationWizardVisible(true);
    };

    return(
        <Layout className="screen-size" style={{...{height: '100%', background: 'white'}}}>
            <LayoutHeader  
                subtitle={Utils.getSubtitle(store!.currentProject)}
                helpMessage="Isolated page for controlled experimentation"
                title="Test projects" 
                buttons={[
                    <Button 
                        key="test-projects-add" 
                        data-id="button-add-test-project" 
                        type="primary" 
                        size="large"
                        onClick={() => wizardStore.setIsCreationWizardVisible(true)}>
                            Create new test project
                    </Button>
                ]}
            />
            <Layout>
                <div style={{marginBottom: 12, width: 300}}>
                    <Input.Search 
                        placeholder="Search test projects..." 
                        value={store.searchValue}
                        onChange={(e) => store.setSearchValue(e.target.value)} 
                        allowClear 
                        onSearch={(val) => store.setSearchValue(val)}
                    />
                </div>
                <TestProjectCreationWizard store={wizardStore} />
                <Content style={{maxHeight: 'calc(100vh - 170px)', overflowY: 'auto'}}>
                    <Table 
                        data-id="test-projects-list"
                        className="alpha-portal-table"
                        dataSource={store.filteredProjects}
                        loading={store.isLoadingProjects || store.isLoadingUsers}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                        rowKey={(record: TestProjectListModel) => record.id}
                        rowClassName={(record: TestProjectListModel) => {
                            const fuzzyPercent = getLastRunFuzzyPercent(record);
                            const hasError = record.lastRunError && record.lastRunError.length > 0;
                            if ((fuzzyPercent != null && fuzzyPercent < 100) || hasError) {
                                return 'row-with-error';
                            }

                            return '';
                        }}
                        onRow={(record: TestProject) => {
                            return {
                                style: {height: 48},
                                onClick: () => store.goToTestProjectDashboard(record.id)
                            };
                        }}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(TestProjectsList);