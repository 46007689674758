import * as React from 'react';
import { observer, inject, Observer } from 'mobx-react';
import { Button, Input, Select, Checkbox, Form, Dropdown, Menu, Tooltip, Popconfirm} from 'antd';
import { ProjectStores } from '../stores';
// import { SearchPackagesAutoCompleteSourceItem } from '../../common/types';
// import { SearchPackagesAutocompleteOptionRendere } from '../../common/components';
import { STORE_UPLOADED_PACKAGES } from '../constants';
import { AutocompleteWithTextSelection } from '../../common/components/AutocompleteWithTextSelection';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { PackageState } from '../../common/models';
import { debounce } from 'lodash';
import { DeletePackagesDialog } from '.';

const packageState = [
    {
        label: 'Ready',
        value: PackageState.Ready
    },
    {
        label: 'Busy',
        value: PackageState.Busy
    },
    {
        label: 'Broken',
        value: PackageState.Broken
    }
];

const FormItem = Form.Item;
const Option = Select.Option;

type Props = ProjectStores;

type State = {
    deletePackagesDialogVisible: boolean
};

class UploadedPackagesFilter extends React.Component<Props, State> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // private optionRenderer: (item: SearchPackagesAutoCompleteSourceItem, idx: number) => any;
    private searchForAutocomplete: (s: string) => void;
    private performSearch: (s: string) => void;

    constructor(props: Props) {
        super(props);

        this.state = {
            deletePackagesDialogVisible: false
        };

        this.searchForAutocomplete = props.uploadedPackagesUI!.searchForAutocomplete.bind(props.uploadedPackagesUI);
        this.performSearch = props.uploadedPackagesUI!.performSearch.bind(props.uploadedPackagesUI);
        this.onStateChange = debounce(this.onStateChange.bind(this), 500);
        // this.optionRenderer = SearchPackagesAutocompleteOptionRendere(props.uploadedPackagesUI!);
    }

    componentWillUnmount() {
        const store = this.props.uploadedPackagesUI!;
        store.setSearchTerm('');
        store.setState([]);
        store.setTags([]);
    }

    getInitialValues() {
        const store = this.props.uploadedPackagesUI!;
    
        return {
            value: store.currentTags,
            search: store.searchTerm
        };
    }

    onStateChange(state: string[]) {
        const store = this.props.uploadedPackagesUI!;
        store.setState(state);
        store.setCurrentPage(1);
    }

    render() {
        const store = this.props.uploadedPackagesUI!;
        const tags = store.tags;

        const menu =  (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={() => store.handlePackagesDownload('pdf')}
                >{`Download ${store.selectionDownloadLabel}`}
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => store.handlePackagesDownload('apkg')}
                >Download apkg
                </Menu.Item>
            </Menu>
        );

        const processMenuRenderer  = (
            <Menu> 
                <Menu.Item key="1" onClick={store.handlePackagesReparse}>Re-parse</Menu.Item>
                <Menu.Item key="2" onClick={store.handlePackagesReindex}>Re-index</Menu.Item>
                {store.isUnprotectedPackageSelected && (
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanAccessAdministration}>
                        <Menu.Item key="3" onClick={() => store.updatePackagesProtection(store.selectedPackages, true)}>Enable packages protection</Menu.Item>
                    </HasPermission>
                )}
                {store.isProtectedPackageSelected && (
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanAccessAdministration}>
                        <Menu.Item key="4" onClick={() => store.updatePackagesProtection(store.selectedPackages, false)}>Disable packages protection</Menu.Item>
                    </HasPermission>
                )}
                <Menu.Item key="5" style={{color: store.isProtectedPackageSelected ? 'lightgray' : 'red'}} disabled={store.isProtectedPackageSelected}>
                    <Popconfirm
                        id="data-id-popconfirm-box-Multi-Delete"
                        title="Are you sure that you want to delete selected packages?"
                        disabled={store.isProtectedPackageSelected}
                        okText="Yes"
                        onConfirm={store.handlePackagesDelete}
                        cancelText="No"
                    >
                Delete
                    </Popconfirm>
                </Menu.Item>
                <Menu.Item
                    key="6"
                    style={{ color: 'red' }}
                    onClick={() => this.setState({ deletePackagesDialogVisible: true })}
                    disabled={store.bulkDeleteInProcess}
                >
                    Bulk delete
                </Menu.Item>
            </Menu>);

        return (
            <>
                <DeletePackagesDialog
                    store={store}
                    isVisible={this.state.deletePackagesDialogVisible}
                    setIsVisible={isVisible => this.setState({ deletePackagesDialogVisible: isVisible })}
                />
                <Form className="ant-advanced-search-form uploaded-packages-filter">
                    <FormItem name="search">
                        <Observer>{() =>
                            <div className="global-search-wrapper" style={{ width: '100%' }}>
                                <AutocompleteWithTextSelection
                                    className="global-search"
                                    style={{ width: 261 }}
                                    options={store.autocompleteSource.map((option, i) => ({
                                        key: i + option.fileName,
                                        value: option.fileName,
                                        label: <span style={{ whiteSpace: 'pre' }}>{option.fileName}</span>
                                    }))}
                                    onSearch={this.searchForAutocomplete}
                                    placeholder="Search..."
                                    defaultActiveFirstOption={false}
                                    showSearch
                                    value={store.searchTerm || ''}
                                    onClear={() => this.performSearch('')}
                                    onSelect={v => {
                                        const value = v as string;
                                        this.performSearch(value);
                                        // store.setSearchTerm(value);
                                    }}
                                    allowClear
                                >
                                    <Input
                                        data-id="uploaded-packages-search"
                                        data-testid="uploaded-packages-search" 
                                        suffix={(
                                            <Button className="search-btn" type="link" onClick={() => this.performSearch(store.searchTerm)}>
                                                <i className="alpha-icon md search-icon"/>
                                            </Button>
                                        )}
                                        // eslint-disable-next-line max-len
                                        onPressEnter={e => {
                                            e.currentTarget.blur(); this.performSearch(e.currentTarget.value); 
                                        }}
                                    />
                                </AutocompleteWithTextSelection>
                            </div>
                        }
                        </Observer>
                    </FormItem>
                    <FormItem name="tags">
                        <Select data-id="uploaded-packages-tags" style={{width: 261}} placeholder="Tags..." mode="tags" onChange={store.setTags}>
                            {tags.map((k, i) => (
                                <Option key={`kw-${i}`} value={k}>{k}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem name="state">
                        <Select data-id="uploaded-packages-state" style={{width: 261}} placeholder="State..." mode="tags" onChange={this.onStateChange}>
                            {packageState.map(s => (
                                <Option key={s.value} value={s.value}>{s.label}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem>
                        <Checkbox data-id="uploaded-packages-source" checked={store.isPackageSourceChecked} onChange={store.handlePackageSourceCheck}>
                            Source Portal (Only)
                        </Checkbox>
                    </FormItem>
                    <FormItem>
                        <Checkbox data-id="uploaded-packages-protected-state" checked={store.isProtectedOnlyChecked} onChange={store.handleIsProtectedOnlyChecked}>
                            Protected
                        </Checkbox>
                    </FormItem>
                    <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditProcessDocuments}>
                        <div className="uploaded-packages-filter-actions">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Tooltip title="Download">
                                    <Button type="link" size="small">
                                        <i className="alpha-icon lg download-icon" />
                                    </Button>
                                </Tooltip>
                            </Dropdown>
                            <Observer>{() =>
                                <Dropdown overlay={processMenuRenderer} trigger={['click']}>
                                    <Tooltip title="Process">
                                        <Button
                                            type="link"
                                            size="small"
                                            loading={store!.isGlobalReindexingInProcess}
                                        ><i className="alpha-icon lg play-icon"/>
                                        </Button>
                                    </Tooltip>
                                </Dropdown>
                            }
                            </Observer>
                            <Observer>{() =>
                                <Tooltip title="Clean up">
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={store.handlePackagesCleanup}
                                        loading={store!.isGlobalCleanupInProcess}
                                    ><i className="alpha-icon lg cleanup-icon" />
                                    </Button>
                                </Tooltip>
                            }
                            </Observer>
                        </div>
                    </HasPermission>
                    {/* <Col span={4} style={{ textAlign: 'right', display: 'block' }}>
                        <Button className="clear-button" style={{ marginLeft: 8 }} onClick={() => store.clearFilter()}>
                            Clear
                        </Button>
                    </Col> */}
                </Form>
            </>
        );
    }
}

export default inject(STORE_UPLOADED_PACKAGES)(observer(UploadedPackagesFilter));